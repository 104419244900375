<template>
  <div>
    <header v-if="IsPC()">
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner3.png"
        alt=""
      />
      <h4>联系方式</h4>
      <p>网营科技期待与您的合作！</p>
    </header>
    <header v-else>
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner-h5.png"
        alt=""
      />
      <h4>联系方式</h4>
      <p>网营科技期待与您的合作！</p>
    </header>
    <div class="foot" v-if="IsPC()" ref="foot">
      <img
        class="bg"
        src="https://prod.oss.netopstec.com/net-foot-bg2.png"
        alt=""
      />
      <div class="body">
        <img
          src="https://prod.oss.netopstec.com/net-logo.png"
          alt=""
          class="logo"
        />
        <div class="text">
          <div class="left">
            <p>官方微信</p>
            <img
              src="https://prod.oss.netopstec.com/net-foot-erweima2.jpg"
              alt=""
              class="erweima"
            />
            <h5>联系我们</h5>
            <li>
              <img
                src="https://prod.oss.netopstec.com/net-foot-icon1.png"
                alt=""
              />
              <span>{{ ContactInfo.otherContacts.companyFax }}</span>
            </li>
            <li>
              <img
                src="https://prod.oss.netopstec.com/net-foot-icon4.png"
                alt=""
              />
              <span>{{ ContactInfo.otherContacts.companyPhone }}</span>
            </li>
            <li>
              <img
                src="https://prod.oss.netopstec.com/net-foot-icon5.png"
                alt=""
              />
              <span>{{ ContactInfo.otherContacts.addressZh }}</span>
            </li>
          </div>
          <div class="right">
            <div
              v-for="(item, index) in ContactInfo.contactsList"
              :key="index"
              class="box"
            >
              <h5>{{ item.deptNameZh }}</h5>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon3.png"
                  alt=""
                />
                <span>{{ item.phone }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon2.png"
                  alt=""
                />
                <span>{{ item.email }}</span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot" v-else ref="foot">
      <img
        class="bg"
        src="https://prod.oss.netopstec.com/net-foot-bg2-h5.png"
        alt=""
      />
      <div class="body">
        <div class="text">
          <div class="right">
            <div
              v-for="(item, index) in ContactInfo.contactsList"
              :key="index"
              class="box"
            >
              <h5>{{ item.deptNameZh }}</h5>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon3.png"
                  alt=""
                />
                <span>{{ item.phone }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon2.png"
                  alt=""
                />
                <span>{{ item.email }}</span>
              </li>
            </div>
            <div class="box">
              <h5>联系我们</h5>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon1.png"
                  alt=""
                />
                <span>{{ ContactInfo.otherContacts.companyFax }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon4.png"
                  alt=""
                />
                <span>{{ ContactInfo.otherContacts.companyPhone }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon5.png"
                  alt=""
                />
                <span>{{ ContactInfo.otherContacts.addressZh }}</span>
              </li>
            </div>
          </div>
          <div class="left">
            <p>官方微信</p>
            <img
              src="https://prod.oss.netopstec.com/net-foot-erweima2.jpg"
              alt=""
              class="erweima"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/utils/http";
export default {
  data() {
    return {
      ContactInfo: {},
    };
  },
  created() {
    this.selectContactInformation();
  },
  methods: {
    selectContactInformation() {
      get("/open/selectContactInformation", {
        terminalType: 0,
      }).then((res) => {
        this.ContactInfo = res;
      });
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  header {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      left: 800px;
      top: 323px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    p {
      position: absolute;
      top: 458px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      left: 176px;
      width: 1569px;
      height: 70px;
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      line-height: 70px;
      animation: bannerLogo 1.5s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
  .foot {
    position: relative;
    h5 {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
    li {
      margin-top: 18px;
      display: flex;
      list-style: none;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        line-height: 27px;
      }
    }
    .bg {
      width: 100%;
    }
    .body {
      opacity: 0;
      position: absolute;
      top: 181px;
      left: 238px;
      z-index: 1;
      animation: introduce_title 1s linear 1s 1;
      animation-fill-mode: forwards;
      .logo {
        width: 343px;
        height: 79px;
      }
      .text {
        margin-top: 26px;
        display: flex;
        .left {
          width: 343px;
          p {
            font-size: 18px;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
          }
          .erweima {
            width: 150px;
            height: 150px;
            margin-top: 20px;
          }
          h5 {
            margin-top: 28px;
          }
        }
        .right {
          margin-left: 216px;
          display: flex;
          flex-wrap: wrap;
          // float: right;
          .box {
            width: 300px;
            margin: 0 26px 105px;
            // height: 15px;
          }
        }
      }
    }
  }
  .footer {
    width: 1920px;
    height: 175px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1100px) {
  header {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      width: 750px;
      text-align: center;
      position: absolute;
      left: 0px;
      top: 400px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    p {
      position: absolute;
      top: 550px;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      padding: 23px;
      width: 300px;
      height: 40px;
      font-size: 22px;
      font-weight: 400;
      color: #ffffff;
      line-height: 45px;
      animation: bannerLogo 1.5s 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
  .foot {
    position: relative;
    h5 {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
    li {
      margin-top: 18px;
      display: flex;
      list-style: none;
      align-items: center;
      white-space: nowrap;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        line-height: 27px;
      }
    }
    .bg {
      width: 750px;
      height: 1232px;
    }
    .body {
      width: 730px;
      opacity: 0;
      position: absolute;
      top: 179px;
      left: 0;
      padding: 0 10px;
      animation: introduce_title 1s linear 1s 1;
      animation-fill-mode: forwards;
      z-index: 1;

      .text {
        margin-top: 26px;
        .right {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .box {
            width: 300px;
            margin: 0 26px 36px;
          }
        }
        .left {
          margin: 50px auto 0;
          width: 343px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            margin: 0 auto;
            font-size: 18px;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
          }
          .erweima {
            width: 150px;
            height: 150px;
            margin-top: 20px;
          }
          h5 {
            margin-top: 28px;
          }
        }
      }
    }
  }
  .footer {
    width: 750px;
    height: 216px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    background-size: 100% 100%;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

@keyframes bannerLogo {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes introduce_title {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>